<template>
  <div class="signin_wrap">
    <div class="signin_layout">
      <div class="signin_layout_form">
        <div class="signin_layout_form__up">
          <img :src="logoUrl" alt="logo" class="signin_layout_form__up__logo" />
          <h1 class="heading-3">{{ title }}</h1>
          <span class="sub-heading-2">{{ subTitle }}</span>
          <slot />
        </div>
        <div>
          <div class="signin_layout_form_help">
            <div class="vertical-center">
              <img alt="svg" src="../../assets/question-mark.svg" />
              <span class="signin_layout_form_help__need"> {{ $t("general.contact.needHelp") }}</span>
              <a :href="telContactPhoneNumber" class="signin_layout_form_help__phone"> {{ contactPhoneNumber }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="signin_layout_image hidden-sm-and-down">
        <div class="signin_layout_image_branding">
          <div class="signin_layout_image_branding_logo">
            <img :src="logoReducedUrl" alt="logo" />
          </div>
          <div class="signin_layout_image_branding_text">
            {{ $t("general.taglines.default") }}
          </div>
          <div class="signin_layout_image_branding_domain">
            <span>{{ brandDomain }}</span>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { mapState } from "pinia";

import Footer from "@/components/Layout/Footer";
import { useAppStore } from "@/pinia-store/app";

export default {
  name: "SignInLayout",
  components: {
    Footer,
  },
  computed: {
    ...mapState(useAppStore, [
      "logoUrl",
      "logoReducedUrl",
      "brandDomain",
      "contactPhoneNumber",
      "telContactPhoneNumber",
    ]),
  },
  props: {
    title: String,
    subTitle: String,
  },
};
</script>
<style lang="scss">
.signin {
  &_wrap {
    min-height: 100vh;
  }

  &_layout {
    width: 100%;
    height: calc(100% - 41px);
    display: flex;
    justify-content: space-between;

    &_image {
      overflow-y: auto;
      flex: 0 0 59%;
      height: 100%;
      background: url("../../assets/sign-in-img.jpg") no-repeat 100% 50%;
      background-size: cover;
      position: relative;

      &_branding {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 297px;
        height: 532px;
        //background: linear-gradient(180deg, #01b1fd 0%, #0183ff 157.96%);
        background: linear-gradient(180deg, var(--primary) 0%, var(--primary) 157.96%);

        &_logo {
          position: absolute;
          width: 161px;
          height: 46px;
          left: 42px;
          top: 36px;

          img {
            height: 100%;
          }
        }

        &_text {
          position: absolute;
          width: 214.5px;
          height: 167.72px;
          top: 162px;
          left: 42px;
          color: white;
          font-style: normal;
          font-weight: 900;
          font-size: 36px;
          line-height: 40px;
          letter-spacing: 0.015em;
        }

        &_domain {
          position: absolute;
          height: 20px;
          left: 42px;
          bottom: 28px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.01em;
          color: #ffffff;
          opacity: 0.64;
        }
      }
    }

    &_form {
      //overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      &__up {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-top: 107px;

        @media (max-width: 767.98px) {
          padding-top: 50px;
        }

        img {
          margin-bottom: 20px;
          @media (max-width: 767.98px) {
            margin-bottom: 24px;
          }
        }

        h1 {
          margin-bottom: 16px;

          @media (max-width: 767.98px) {
            margin-bottom: 12px;
            font-size: 24px;
          }
        }

        .sub-heading-2 {
          margin: 0 auto 30px;
          max-width: 350px;
          @media (max-width: 767.98px) {
            margin-bottom: 12px;
          }
        }

        &__logo {
          height: 64px;
          width: fit-content;
          align-self: center;
        }
      }

      &_help {
        width: 100%;
        padding: 31px 0;
        background: rgba(231, 232, 242, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;

        text-align: center;
        letter-spacing: 0.32px;

        color: rgba(51, 52, 62, 0.36);

        @media (max-width: 767.98px) {
          height: 115px;
        }

        &__need {
          margin: 0 12px;
        }

        &__phone {
          color: rgba(51, 52, 62, 0.9);
          text-decoration: none;
        }
      }

      &_footer {
        height: 50px;
        padding: 16px 26px 17px 26px;
        border-top: 1px solid $primarylight2;
        display: flex;
        justify-content: space-between;

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;

          color: $primaryblack;

          mix-blend-mode: normal;
          opacity: 0.4;
        }
      }
    }
  }
}
</style>
